<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getUserInfoApi } from '@/api/system'

export default {
  mounted() {
    window.addEventListener('resize', () => {
      this.$store.commit('updateWindowHeight', document.documentElement.clientHeight)
    })
    const path = window.location.href.split('/').pop()
    if (localStorage.getItem('token')) {
      getUserInfoApi().then(res => {
        this.$store.commit('updateUsername', res.info.nickname)
        this.$store.commit('updateUserPoint', res.info.point)
      })
    } else {
      if (path && path != 'home') this.$router.push('/home')
    }
    this.$store.commit('updateCurrentPath', path ? `/${path}` : '/home')
  },
}
</script>

<style lang="scss">
@import '@/assets/css/global.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

div::-webkit-scrollbar {
  width: 4px;
  background: $color-background;
}

div::-webkit-scrollbar-thumb {
  background: #808695;
}
</style>
